import { postRequest, getRequest } from './index'
import { BSERURL, APISRCUL, SRCURL } from '@/config'

// 商城-品类列表查询
export const goodsGetGoodsCategoryList = (successCallback, failureCallback) => {
  getRequest(`${BSERURL}/goods/getGoodsCategoryList`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城-规格列表查询
export const goodsGetGoodsSpecList = (categoryCode, successCallback, failureCallback) => {
  getRequest(`${BSERURL}/goods/getGoodsSpecList/${categoryCode}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城-材质列表查询
export const goodsGetGoodsMaterialList = (categoryCode, successCallback, failureCallback) => {
  getRequest(`${BSERURL}/goods/getGoodsMaterialList/${categoryCode}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 现货大厅-商品列表
export const goodsHallPageSpotHallGoods = (data, successCallback, failureCallback) => {
  postRequest(`${APISRCUL}/goods/hall/pageSpotHallGoods`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 现货大厅-商品详情
export const goodsHallDetailDetailSpotHallGoods = (data, successCallback, failureCallback) => {
  getRequest(`${APISRCUL}/goods/hall/detailSpotHallGoods/${data}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 新增求购
export const tradeWantToBuySave = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/wantToBuy/save`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 求购详情
export const tradeWantToBuyDetail = (data, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/wantToBuy/detail${data}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 运营后台-求购列表
export const TradeWantToBuyPage = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/wantToBuy/page`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
