<template>
  <div class="page-content">
    <el-form ref="ruleFormRef" :model="formData">
      <div v-for="(domain, index) in formData.tradeWantToBuyGoodsInfoReqs" :key="index" class="row top-row">
        <el-form-item label="商品类别" :prop="'tradeWantToBuyGoodsInfoReqs.' + index + '.goodsCategoryIdAggregate'" :rules="rules.selectiveType">
          <el-cascader
            ref="goodsCategoryRef"
            v-model="domain.goodsCategoryIdAggregate"
            placeholder="请选择"
            :options="categoryOption"
            :props="categoryCascadeProps"
            clearable
            @change="(value) => categoryChange(value, index)"
          />
        </el-form-item>
        <el-form-item label="商品材质" :prop="'tradeWantToBuyGoodsInfoReqs.' + index + '.goodsMaterialName'" :rules="rules.selectiveType">
          <el-select
            v-model="domain.goodsMaterialName"
            placeholder="请选择商品材质"
            @change="(value) => getGoodsMaterial(value, index)"
          >
            <el-option
              v-for="item in domain.goodsMaterialOption"
              :key="item.materials"
              :label="item.materials"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品规格" :prop="'tradeWantToBuyGoodsInfoReqs.' + index + '.goodsSpecName'" :rules="rules.selectiveType">
          <el-select
            v-model="domain.goodsSpecName"
            placeholder="请选择商品规格"
            @change="(value) => getGoodsSpecs(value, index)"
          >
            <el-option
              v-for="item in domain.goodsSpecOption"
              :key="item.specs"
              :label="item.specs"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="钢厂/厂家" :prop="'tradeWantToBuyGoodsInfoReqs.' + index + '.manufactor'" :rules="[{ min: 1, max: 15, message: '不超过15个字符', trigger: 'blur' }]">
          <el-input v-model="domain.manufactor" placeholder="请输入钢厂/厂家" />
        </el-form-item>
        <el-form-item label="含税单价（元）" :prop="'tradeWantToBuyGoodsInfoReqs.' + index + '.unitPriceIncludingTax'" :rules="rules.message">
          <el-input-number v-model="domain.unitPriceIncludingTax" :precision="2" :step="1" :min="0.01" placeholder="请输入含税单价" />
        </el-form-item>
        <el-form-item label="求购重量（吨）" :prop="'tradeWantToBuyGoodsInfoReqs.' + index + '.quantity'" :rules="rules.message">
          <el-input-number v-model="domain.quantity" :precision="3" :step="1" :min="0.001" placeholder="请输入求购重量" />
        </el-form-item>
        <span v-show="formData.tradeWantToBuyGoodsInfoReqs.length > 1" class="delete-row" @click="deleteWantToBuyGoods(index)">删除</span>
      </div>
      <el-button v-show="formData.tradeWantToBuyGoodsInfoReqs.length < 20" type="primary" plain @click="addWantToBuyGoods">
        添加
      </el-button>
      <el-form-item label="附件">
        <ImageUpload :upload-file-list.sync="images" />
      </el-form-item>
      <el-form-item label="收货地址" prop="receivingArea" :rules="rules.selectiveType">
        <el-cascader
          v-model="formData.receivingArea"
          :props="{ label: 'name', value: 'name', children: 'children' }"
          :options="allCityOption"
        />
      </el-form-item>
      <el-form-item label="发布截止时间" prop="deadLine" :rules="rules.deadLine">
        <el-radio-group v-model="deadLine" @change="getDeadLine">
          <el-radio label="3">
            3天
          </el-radio>
          <el-radio label="7">
            7天
          </el-radio>
          <el-radio label="-1">
            长期有效
          </el-radio>
          <el-radio label="9">
            其他时间
            <el-date-picker
              v-if="deadLineDisable"
              v-model="formData.deadLine"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              :picker-options="setDateRange"
            />
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="期望收货日期" prop="expectedReceiptDate">
        <el-date-picker
          v-model="formData.expectedReceiptDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="setDateRange"
        />
      </el-form-item>
      <el-form-item label="支付方式" prop="paymentMethod" :rules="rules.selectiveType">
        <el-checkbox-group v-model="formData.paymentMethod">
          <el-checkbox label="0">
            先款后货
          </el-checkbox>
          <el-checkbox label="1">
            货到付款
          </el-checkbox>
          <el-checkbox label="2">
            账期支付
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <div class="row contact-row">
        <el-form-item label="求购联系人" prop="contact" :rules="[...rules.message, { min: 2, max: 20, message: '长度为2~20个字符', trigger: 'blur' }]">
          <el-input v-model="formData.contact" />
        </el-form-item>
        <el-form-item label="" prop="gender" :rules="rules.selectiveType">
          <el-radio-group v-model="formData.gender">
            <el-radio label="0">
              先生
            </el-radio>
            <el-radio label="1">
              女士
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="phone" :rules="rules.phone">
          <el-input v-model="formData.phone" placeholder="填写手机号" />
        </el-form-item>
      </div>
      <el-form-item class="contact-row" label="求购备注" prop="remarks">
        <el-input v-model="formData.remarks" type="textarea" placeholder="填输入求购备注" />
      </el-form-item>
    </el-form>
    <div class="form-operation">
      <el-button type="primary" :loading="$store.state.loading" @click="submitForm">
        发布求购
      </el-button>
    </div>
  </div>
</template>

<script>
import { goodsGetGoodsCategoryList, goodsGetGoodsSpecList, goodsGetGoodsMaterialList, tradeWantToBuySave } from '@/http/hangOut'
import { getSelectAreaTree } from '@/http/common'
import { rules } from '@/unit/matchFormRegex'
import ImageUpload from '@/components/ImageUpload.vue'
export default {
  components: { ImageUpload },
  data() {
    const validateDeadLine = (rule, value, callback) => {
      if (!this.formData.longLine && !this.formData.deadLine) {
        callback(new Error('请选择'))
      }
      callback()
    }
    return {
      rules: { ...rules,
        deadLine: [{ required: true, validator: validateDeadLine, trigger: 'change' }]
      },
      categoryOption: [],
      goodsSpecOption: [],
      goodsMaterialOption: [],
      images: [],
      deadLine: '',
      deadLineDisable: false,
      formData: {
        deadLine: '',
        receivingArea: [], // 地区
        paymentMethod: [],
        expectedReceiptDate: '',
        tradeWantToBuyGoodsInfoReqs: [{
          goodsCategoryIdAggregate: [],
          goodsMaterialOption: [],
          goodsSpecOption: []
        }]
      },
      // 划级联渲染规则
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName',
        children: 'children',
        emitPath: true
      },
      // 地区
      allCityOption: [],
      setDateRange: {
        disabledDate: time => {
          // 禁用今天之后的日期【当前天可选】
          return time.getTime() < Date.now() - 86400000
        }
      }
    }
  },
  watch: {
    images: {
      handler(val) {
        if (!val || val.length < 1) return
        this.formData.images = []
        val.map((item) => {
          this.formData.images.push(item.url)
        })
        this.formData.images = this.formData.images.toString()
      }
    }
  },
  mounted() {
    this.getCategoryList()
    // 获取所有的城市
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
  },
  methods: {
    // 发布求购
    async submitForm() {
      const valid = await this.$refs.ruleFormRef.validate().catch(() => false)
      if (!valid) return
      this.formData.cmpId = JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)).cmpId
      this.formData.cmpName = JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)).orgName
      const params = { ...this.formData }
      params.receivingArea = params.receivingArea.toString()
      params.paymentMethod = params.paymentMethod.toString()
      tradeWantToBuySave(params, res => {
        this.$message.success('保存成功')
        this.$router.push({
          path: '/buyHall/wantBuyDetail',
          query: { id: res.msg }
        })
      })
    },
    // 添加求购货物
    addWantToBuyGoods() {
      this.formData.tradeWantToBuyGoodsInfoReqs.push({
        goodsCategoryIdAggregate: [],
        goodsMaterialOption: [],
        goodsSpecOption: []
      })
    },
    // 删除求购货物
    deleteWantToBuyGoods(index) {
      this.formData.tradeWantToBuyGoodsInfoReqs.splice(index, 1)
    },
    // 递归处理级联数据
    getTreeData(data) {
      data.map((item) => {
        if (item.children.length === 0) {
          item.children = null
        } else {
          this.getTreeData(item.children)
        }
      })
    },
    // 品类 级联选择器数据
    getCategoryList() {
      goodsGetGoodsCategoryList(res => {
        this.getTreeData(res.data)
        this.categoryOption = res.data
      })
    },
    // 级联品类change事件
    categoryChange(val, index) {
      // eslint-disable-next-line no-return-assign
      if (!val || val.length === 0) return this.goodsSpecOption = this.goodsMaterialOption = []
      const node = this.$refs['goodsCategoryRef'][index].getCheckedNodes()[0].pathNodes
      this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsOneCategoryId = node[1].value
      this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsOneCategoryName = node[1].label
      this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsTwoCategoryId = node[2].value
      this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsTwoCategoryName = node[2].label
      this.associativeSearch(node[2].value, index)
    },
    // 材质跟型号的搜索
    associativeSearch(id, index) {
      if (!id) return
      goodsGetGoodsMaterialList(id, res => {
        this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsMaterialOption = [...res.data]
      })
      goodsGetGoodsSpecList(id, res => {
        this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsSpecOption = [...res.data]
      })
    },
    // 获取商品材质
    getGoodsMaterial(val, index) {
      this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsMaterialId = val.id
      this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsMaterialName = val.materials
    },
    // 获取商品规格
    getGoodsSpecs(val, index) {
      this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsSpecId = val.id
      this.formData.tradeWantToBuyGoodsInfoReqs[index].goodsSpecName = val.specs
    },
    // 发布截至日期
    getDeadLine(val) {
      const date = new Date()
      // 3天(3) 7天(7) 长期有效(-1) 其他时间(9)
      this.formData.longLine = false
      switch (val) {
        case '3':
          date.setDate(date.getDate() + 3)
          this.formData.deadLine = `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`
          this.deadLineDisable = false
          break
        case '7':
          date.setDate(date.getDate() + 7)
          this.formData.deadLine = `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`
          this.deadLineDisable = false
          break
        case '-1':
          this.formData.deadLine = ''
          this.formData.longLine = true
          this.deadLineDisable = false
          break
        default:
          this.formData.deadLine = ''
          this.deadLineDisable = true
          break
      }
      this.validateField('deadLine')
    },
    // 表单部分字段校验
    validateField(field) {
      this.$refs.ruleFormRef.validateField(field)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  padding: 20px 0;
  width: $centerPlateWidth;
  margin: auto;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.top-row {
   .el-form-item {
    width: 170px;
  }
}
.contact-row {
  width: 50%;
}
.form-operation {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.el-button {
  padding: 10px 46px;
  height: 40px;

  &--primary {
    background-color: #D71618;
    border-color: #D71618;

    &.is-plain {
      height: 36px;
      border-color: #E1251B;
      background-color: #fff;
      color: #E1251B;
    }
  }
}
.delete-row {
  display: inline-block;
  color: #2680EB;
  align-self: center;
  margin-top: 15px;
  cursor: pointer;
}
</style>
